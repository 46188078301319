import React from 'react';
import logo from './images/TechWeave Logo.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo-container">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div className="holding-container">
          <p>
            We're TechWeave Creative and provide website consultancy, design and development. We also specialise in Shopify development, which is nice!
          </p>
          <div className="subtitle">
            We have years of experience in both front and back-end web development.<br />
            What you're looking at is our holding page, it isn't going to blow any minds but we believe in keeping it simple...<br />
            If you're intrigued and would like to know more or to make an enquiry, contact us<br />
            <div className="link-wrapper">
              <a
                className="App-link link-hover"
                href="mailto:contact@techweavecreative.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                @TechWeave Creative
              </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
